<template>
  <div
    v-if="value"
    class="preview-box"
  >
    <div class="preview-button">
      <span
        class="close-btn"
        @click="close"
      >
        <svg
          class="icon"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
        >
          <path
            d="M625.066667 512L997.12 136.533333c30.72-30.72 30.72-81.92 0-112.64-30.72-30.72-81.92-30.72-112.64 0l-372.053333 375.466667L140.373333 23.893333C109.653333-6.826667 58.453333-6.826667 27.733333 23.893333-2.986667 54.613333-2.986667 105.813333 27.733333 136.533333l372.053334 375.466667L27.733333 887.466667c-30.72 30.72-30.72 81.92 0 112.64 15.36 15.36 35.84 23.893333 56.32 23.893333 20.48 0 39.253333-8.533333 56.32-23.893333l372.053334-375.466667 372.053333 375.466667c15.36 15.36 35.84 23.893333 56.32 23.893333 20.48 0 40.96-8.533333 56.32-23.893333 30.72-30.72 30.72-81.92 0-112.64L625.066667 512z"
            fill="#ffffff"
          ></path>
        </svg>

      </span>
    </div>
    <div class="preview-img">
      <img :src="imgUrl">
    </div>
  </div>
</template>
<script>
export default {
  props: ['imgUrl', 'value'],
  methods: {
    close() {
      this.$emit('input', !this.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.preview-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  // padding: 40px;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  // & > div {
  //   position: relative;
  // }
  .preview-button {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
    cursor: pointer;
    z-index: 11;
  }
  .preview-img {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // max-width: 90%;
      // max-height: 90%;
    }
  }
}
</style>
