<template>
  <section class="timeline-item">
    <div class="item">
      <span
        :style="getBackgroundColour(itemTimeline.color)"
        class="dot"
      />
      <!-- <h3 class="date-item">{{ getFormattedDate(itemTimeline) }}</h3> -->
      <!-- <h4
        class="title-item"
        v-html="itemTimeline.title"
      /> -->
      <p
        class="description-item"
        v-html="itemTimeline.description"
        @click="handlePreview"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'TimelineItem',
  props: {
    itemTimeline: {
      type: Object,
      default: () => ({})
    },
    colorDots: {
      type: String,
      default: '#2da1bf'
    },
    dateLocale: {
      type: String,
      default: ''
    }
  },
  methods: {
    getBackgroundColour(color) {
      return color ? `background:${color}` : `background:${this.colorDots}`
    },
    getFormattedDate(item) {
      const locale = this.dateLocale || window.navigator.language
      const nameMonth = item.from.toLocaleDateString(locale, { month: 'long' })
      if (item.showDayAndMonth) {
        const day = item.from.getDate()
        return `${day}. ${nameMonth}`
      }

      return nameMonth
    },
    handlePreview() {
      this.$emit('preview', this.itemTimeline.description)
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-item {
  .item {
    border-left: 2px solid rgba(0,0,0,0.06);
    padding: 20px 0 20px 15px;
    position: relative;
  }
  .date-item {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .title-item {
    margin: 0;
    padding: 5px 0;
    font-size: 15px;
    font-weight: 500;
  }
  .description-item {
    font-weight: 100;
    margin: 0;
  }
  .dot {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: -7px;
    top: 26px;
    background: #fff !important;
    border: 2px solid #1890FF;
  }
  .description-item {
    cursor: pointer;
  }
}
</style>
